<template>
  <b-row>
    <!-- v-if="!hasPurchaseOrder" -->
    <b-col
      v-if="false"
      md="12"
      class="mb-1 d-flex justify-content-end"
    >
      <e-button
        :text="$t('Adicionar parcela')"
        variant="primary"
        @click="showInstallmentSidebar"
      />
    </b-col>

    <b-col md="12">
      <FormulateForm
        ref="formBillPayment"
        name="formBillPayment"
      >
        <b-row>
          <b-col>
            <b-table
              show-empty
              responsive
              class="bordered"
              :fields="fields"
              :empty-text="$t('Nenhuma parcela adicionada')"
              :items="installmentsList"
            >
              <template #cell(action)="row">
                <e-grid-actions
                  :show-update="!row.item.paymentDate"
                  :show-delete="false"
                  @update="showInstallmentSidebar(row.item)"
                />
                <!-- :show-delete="!row.item.paymentDate" -->
                <!-- @delete="deleteInstallment(row.item)" -->
              </template>

              <template #custom-foot>
                <tr>
                  <th
                    colspan="5"
                    class="text-right"
                  >
                    Total
                  </th>
                  <th class="text-right">
                    {{ getTotalInfos.totalValue | currency }}
                  </th>
                  <th class="text-right">
                    {{ getTotalInfos.totalPaidValue | currency }}
                  </th>
                  <th />
                  <th class="text-right">
                    {{ getTotalInfos.totalDiscountValue | currency }}
                  </th>
                  <th />
                </tr>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </FormulateForm>
    </b-col>

    <account-payable-installment-sidebar
      ref="installmentSidebar"
      is-expense
      :is-from-purchase="!!formData.purchaseOrderId"
      :is-from-sale="!!formData.saleId"
      @update-installment="updateInstallment"
      @add-installment="addInstallment"
    />
  </b-row>
</template>

<script>
import { BRow, BCol, BTable } from 'bootstrap-vue'
import { paymentTypes } from '@/mixins'
import EGridActions from '@/views/components/EGridActions.vue'
import { mapState } from 'vuex'
import EButton from '../../../components/EButton.vue'
import AccountPayableInstallmentSidebar from './AccountPayableInstallmentSidebar.vue'

export default {
  components: { BRow, BCol, BTable, EButton, AccountPayableInstallmentSidebar, EGridActions },

  mixins: [paymentTypes],

  props: {
    formData: {
      type: Object,
      required: true,
    },
    installmentsList: {
      type: Array,
      required: true,
      default: () => [],
    },
    hasPurchaseOrder: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {}
  },

  computed: {
    ...mapState('pages/financial/accountsPayable/expenseMaintain', ['expenseForm']),
    hasInstallments() {
      return this.installmentsList.length > 0
    },

    getTotalInfos() {
      return this.installmentsList.reduce(
        (totals, inst) => ({
          totalValue: (inst.value || 0) + totals.totalValue,
          totalPaidValue: (inst.paymentValue || 0) + totals.totalPaidValue,
          totalDiscountValue: (inst.discount || 0) + totals.totalDiscountValue,
        }),
        { totalValue: 0, totalPaidValue: 0, totalDiscountValue: 0 }
      )
    },

    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Parcela'),
          key: 'installment',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '80px' },
        },
        {
          label: this.$t('Forma de pagamento'),
          key: 'paymentMethod',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '150px', minWidth: '150px' },
          formatter: val => val.name,
        },
        {
          label: this.$t('Dt Vencimento'),
          key: 'dueDate',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          formatter: val => this.$options.filters.date(val),
        },
        {
          label: this.$t('Dt Pagamento'),
          key: 'paymentDate',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          formatter: val => this.$options.filters.date(val),
        },
        {
          label: this.$t('Valor'),
          key: 'value',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: { width: '120px', minWidth: '120px' },
          formatter: val => this.$options.filters.currency(val),
        },
        {
          label: this.$t('Valor pago'),
          key: 'paymentValue',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: { width: '120px', minWidth: '120px' },
          formatter: val => this.$options.filters.currency(val),
        },
        {
          label: this.$t('Juros'),
          key: 'interest',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: { width: '100px', minWidth: '100px' },
          formatter: val => this.$options.filters.percentage(val),
        },
        {
          label: this.$t('Desconto'),
          key: 'discount',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: { width: '120px', minWidth: '120px' },
          formatter: val => this.$options.filters.currency(val),
        },
        {
          label: this.$t('Observação'),
          key: 'comments',
          thClass: 'text-center',
          tdClass: 'text-left',
        },
      ]
    },
  },

  watch: {},

  methods: {
    showInstallmentSidebar(item) {
      const { value, firstDueDate, installmentQuantity, paymentMethodToInstallments } =
        this.expenseForm
      if (!(value && firstDueDate && installmentQuantity && paymentMethodToInstallments)) {
        this.showWarning({
          message: this.$t('ACCOUNTS_PAYABLE.UI.INSTRUCTION.INSTALLMENT_FIRST_ADD_VALIDATION'),
        })
        return
      }
      this.$refs.installmentSidebar.show(item)
    },

    addInstallment(installmentData) {
      this.$emit('add-installment', installmentData)
    },
    updateInstallment(installmentData) {
      this.$emit('update-installment', installmentData)
    },
    // async deleteInstallment(item) {
    //   const confirmed = await this.confirm({
    //     text: this.$t('A parcela será excluída.'),
    //   })
    //   if (!confirmed) return
    //   this.$emit('remove-installment', item)
    // },

    isValid() {
      this.$refs.formBillPayment.showErrors()

      return this.installmentsList.length > 0 && this.$refs.formBillPayment.isValid
    },
  },
}
</script>

<style></style>
