<template>
  <FormulateForm
    ref="formBill"
    name="formBill"
  >
    <b-row>
      <b-col md="3">
        <FormulateInput
          v-if="isRecordTypeAuto || hasInstallmentPaid || hasPurchaseOrder"
          v-model="formData.store"
          type="label"
          filter="storeName"
          :title="$t('ACCOUNTS_PAYABLE.VALIDATION_ERROR.STORE_ID_CAN_NOT_CHANGE')"
          :label="$t('Loja')"
        />
        <e-store-combo
          v-else
          v-model="formData.storeId"
          :required="!hasPurchaseOrder"
          only-active-options
        />
      </b-col>
      <b-col
        v-if="isEdit"
        md="2"
      >
        <FormulateInput
          v-if="formData.purchaseOrderId"
          id="bill-purchase_order"
          v-model="formData.purchaseOrderId"
          :label="$t('Id do pedido')"
          type="label"
        />
        <FormulateInput
          v-if="formData.saleId"
          id="bill-sale_id"
          v-model="formData.saleId"
          :label="$t('Id da venda')"
          type="label"
        />
        <FormulateInput
          v-if="formData.payBox"
          id="bill-pay_box_id"
          v-model="formData.payBox.number"
          :label="$t('Caixa')"
          type="label"
        />
      </b-col>
      <b-col :md="isEdit ? 3 : 5">
        <FormulateInput
          id="bill-description"
          v-model="formData.description"
          :label="$t('Descrição')"
          type="text"
          class="required"
          validation="required"
        />
      </b-col>
      <b-col md="2">
        <FormulateInput
          id="bill-document-type"
          v-model="formData.documentType"
          :label="$t('Tipo do documento')"
          type="vue-select"
          :options="documentTypeOptions()"
        />
      </b-col>
      <b-col md="2">
        <e-only-number-input
          id="bill-document"
          v-model="formData.document"
          required
          :label="$t('Nº do documento')"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col md="2">
        <FormulateInput
          v-if="canEditFieldsToUpdateInstallments && !hasPurchaseOrder"
          id="bill-value"
          v-model="formData.value"
          name="billValue"
          :label="$t('Valor')"
          type="text-number"
          filter="currency"
          currency="R$"
          :precision="2"
          class="required"
          validation="^required|min:0.01"
          :instructions="[
            { text: $t('Caso altere uma parcela, este valor será alterado.') },
            {
              text: $t('ACCOUNTS_PAYABLE.UI.INSTRUCTION.INSTALLMENT_GENERATION'),
            },
          ]"
          @blur="updateInstallments"
        />
        <FormulateInput
          v-else
          id="bill-value"
          :value="formData.value"
          :label="$t('Valor')"
          name="billValue"
          type="label"
          :title="$t(getTitleWhenCantUpdateValues)"
          filter="currency"
          :disabled="hasPurchaseOrder"
          :instruction="$t('Caso altere uma parcela, este valor será alterado.')"
        />
      </b-col>

      <b-col
        v-if="isExpense"
        md="2"
      >
        <FormulateInput
          v-if="canEditFieldsToUpdateInstallments"
          id="bill-expense-installment-quantity"
          v-model.number="formData.installmentQuantity"
          name="installmentQuantity"
          type="number"
          :label="$t('Qtd. Parcelas')"
          validation="^required|min:1"
          class="required"
          :instructions="[
            { text: $t('Quantidade de parcelas que a despesa possui.') },
            {
              text: $t('ACCOUNTS_PAYABLE.UI.INSTRUCTION.INSTALLMENT_GENERATION'),
            },
          ]"
          @blur="updateInstallments"
        />
        <FormulateInput
          v-else
          id="bill-expense-installment-quantity"
          v-model.number="formData.installmentQuantity"
          name="installmentQuantity"
          :label="$t('Qtd. Parcelas')"
          :title="$t(getTitleWhenCantUpdateValues)"
          type="label"
          :instruction="$t('Quantidade de parcelas que a despesa possui.')"
        />
      </b-col>
      <b-col md="2">
        <FormulateInput
          v-if="canEditFieldsToUpdateInstallments"
          id="bill-due-date"
          v-model="formData.firstDueDate"
          name="firstDueDate"
          :label="$t('Primeiro Venc.')"
          type="datepicker"
          :placeholder="$t('dd/mm/aaaa')"
          dropup
          class="required"
          validation="required"
          :instructions="[
            {
              text: $t(
                'Data de vencimento da primeira parcela. Essa data é usada como base para gerar as outras parcelas. Ex: 03/05, 03/06, 03/07, etc.'
              ),
            },
            {
              text: $t('ACCOUNTS_PAYABLE.UI.INSTRUCTION.INSTALLMENT_GENERATION'),
            },
          ]"
          @blur="updateInstallments"
        />
        <FormulateInput
          v-else
          id="bill-due-date"
          v-model="formData.firstDueDate"
          name="firstDueDate"
          :label="$t('Primeiro Venc.')"
          type="label"
          filter="date"
          :title="$t(getTitleWhenCantUpdateValues)"
          :instruction="
            $t(
              'Data de vencimento da primeira parcela. Essa data é usada como base para gerar as outras parcelas. Ex: 03/05, 03/06, 03/07, etc.'
            )
          "
        />
      </b-col>
      <b-col
        v-if="isExpense"
        md="3"
      >
        <e-payment-method
          id="bill-expense-payment_method"
          v-model="formData.paymentMethodToInstallments"
          name="paymentMethodToInstallments"
          :placeholder="$t('Selecione')"
          :clearable="false"
          :required="canEditFieldsToUpdateInstallments"
          :is-read-only="!canEditFieldsToUpdateInstallments"
          value-is-object
          :title="$t(getTitleWhenCantUpdateValues)"
          :instructions="[
            {
              text: $t(
                'Forma de pagamento da primeira parcela. Ela é usado como base para gerar as outras parcelas.'
              ),
            },
            {
              text: $t('ACCOUNTS_PAYABLE.UI.INSTRUCTION.INSTALLMENT_GENERATION'),
              hide: !canEditFieldsToUpdateInstallments,
            },
          ]"
          @input="updateInstallments"
        />
      </b-col>
      <b-col
        v-if="isExpense"
        md="3"
      >
        <e-management-account
          id="bill-expense-management_account"
          v-model="formData.managementAccountId"
          required
          name="billExpenseManagementAccount"
        />
      </b-col>
      <b-col
        v-if="isReceive"
        md="4"
      >
        <!-- TODO talvez o incomeType seja o managementAccount tbm -->
        <FormulateInput
          id="bill-income-type"
          v-model="formData.incomeType"
          :label="$t('Tipo de receita')"
          type="select-with-button"
          :placeholder="$t('Selecione')"
          :options="incomeTypes"
          class="required"
          validation="required"
          :button_permission="true"
        />
      </b-col>

      <template v-if="isExpense">
        <b-col md="2">
          <FormulateInput
            id="bill-person_type"
            v-model="formData.personCategory"
            name="personCategory"
            :type="isRecordTypeAuto ? 'label' : 'radio'"
            :options="isRecordTypeAuto ? personCategoryOptions() : personCategoryRadioOptions()"
            :label="$t('Categoria do credor')"
            :element-class="['d-flex', 'mt-1', 'flex-wrap']"
          />
        </b-col>
        <b-col md="6">
          <div v-show="isCustomerCategory">
            <e-person-search
              id="bill-customer"
              v-model="formData.customerId"
              name="billPersonCustomer"
              is-customer
              :label="$t('Cliente')"
              :default-options="getDefaultCustomerOptions"
              :required="isCustomerCategory && !hasPurchaseOrder"
              :is-read-only="!!formData.saleId"
            />
          </div>
          <div v-show="isSupplierCategory">
            <e-person-search
              id="bill-supplier"
              v-model="formData.supplierId"
              name="billPersonSupplier"
              is-supplier
              :label="$t('Fornecedor')"
              :default-options="getDefaultSupplierOptions"
              :required="isSupplierCategory && !hasPurchaseOrder"
              :is-read-only="hasPurchaseOrder"
            />
          </div>
        </b-col>
      </template>
      <b-col
        v-if="isReceive"
        md="6"
      >
        <FormulateInput
          id="bill-client"
          v-model="formData.client"
          :label="$t('Cliente')"
          type="select-searchable"
          :placeholder="$t('Cliente')"
          class="required"
          validation="required"
          :options="clientsFound"
          @fetchSearch="searchClient"
        />
      </b-col>
      <b-col md="4">
        <FormulateInput
          id="bill-statement-date"
          v-model="formData.statementDate"
          :label="$t('Data da Emissão')"
          type="datepicker"
          :placeholder="$t('dd/mm/aaaa')"
          dropup
          :disabled="hasPurchaseOrder"
          class="required"
          validation="required"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <FormulateInput
          id="bill-comments"
          v-model="formData.comments"
          :label="$t('Observação')"
          type="textarea"
          rows="2"
        />
      </b-col>
    </b-row>
  </FormulateForm>
</template>

<script>
import _ from 'lodash'
import { BRow, BCol } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import { accountTypes, financialDomain, personTypes } from '@/mixins'
import { EStoreCombo, EPersonSearch, EPaymentMethod, EManagementAccount } from '@/views/components'
import EOnlyNumberInput from '@/views/components/inputs/EOnlyNumberInput.vue'

export default {
  components: {
    BRow,
    BCol,
    EStoreCombo,
    EPersonSearch,
    EPaymentMethod,
    EManagementAccount,
    EOnlyNumberInput,
  },

  mixins: [accountTypes, financialDomain, personTypes],

  props: {
    formData: {
      type: Object,
      required: true,
    },
    isExpense: {
      type: Boolean,
      default: false,
    },
    isReceive: {
      type: Boolean,
      default: false,
    },
    hasPurchaseOrder: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('pages/financial/accountsPayable/expenseMaintain', [
      'hasInstallmentPaid',
      'canEditFieldsToUpdateInstallments',
      'getTitleWhenCantUpdateValues',
    ]),
    ...mapGetters('common/clients', { clientsFound: 'getComboClients' }),

    getDefaultSupplierOptions() {
      return this.formData.supplier ? [this.formData.defaultSupplier] : []
    },
    getDefaultCustomerOptions() {
      return this.formData.customer ? [this.formData.defaultCustomer] : []
    },

    incomeTypes() {
      return [
        { value: 'balcony', label: this.$t('Balcão') },
        { value: 'app_site', label: this.$t('App/Site') },
        { value: 'budget', label: this.$t('Orçamento') },
        { value: 'others', label: this.$t('Outros') },
      ]
    },

    isEdit() {
      return !!this.formData.id
    },

    isRecordTypeAuto() {
      return this.recordtypeEnum.AUTO === this.formData.recordType
    },

    isCustomerCategory() {
      return this.formData.personCategory === this.personCategoryEnum.CUSTOMER
    },
    isSupplierCategory() {
      return this.formData.personCategory === this.personCategoryEnum.SUPPLIER
    },
  },

  mounted() {
    this.fetchManagementAccounts()
  },

  methods: {
    ...mapActions('common/managementAccounts', ['fetchManagementAccounts']),
    ...mapActions('common/clients', {
      stSearchClients: 'searchClients',
    }),

    async searchClient(loading, text) {
      try {
        loading(true)
        await this.stSearchClients({ clientSearch: text })
      } catch (error) {
        console.error(error)
      } finally {
        loading(false)
      }
    },

    isValid() {
      this.$refs.formBill.showErrors()
      return this.$refs.formBill.isValid
    },

    // eslint-disable-next-line func-names
    updateInstallments: _.debounce(function () {
      if (!this.isExpense || this.isLoading) return

      this.$emit('update-installments')
    }, 300),
  },
}
</script>

<style></style>
