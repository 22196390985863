var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("e-sidebar", {
    attrs: {
      show: _vm.showSidebar,
      fetching: _vm.loading,
      title: _vm.isEdit
        ? _vm.$t("Alterar parcela") +
          " " +
          _vm.payableInstallmentForm.installment
        : _vm.$t("Nova parcela"),
      width: "600px",
    },
    on: { save: _vm.saveInstallment, hidden: _vm.hide },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "FormulateForm",
              {
                ref: "payableInstallmentForm",
                attrs: { name: "payableInstallmentForm" },
              },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c("e-payment-method", {
                          attrs: {
                            id: "installment_sidebar-payment_method",
                            placeholder: _vm.$t("Selecione"),
                            required: true,
                            "is-read-only":
                              _vm.isEdit && _vm.isFromPurchasOrSale,
                            "value-is-object": "",
                          },
                          model: {
                            value: _vm.payableInstallmentForm.paymentMethod,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.payableInstallmentForm,
                                "paymentMethod",
                                $$v
                              )
                            },
                            expression: "payableInstallmentForm.paymentMethod",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { md: "8" } },
                      [
                        _c("FormulateInput", {
                          staticClass: "required",
                          attrs: {
                            id: "installment_sidebar-value",
                            label: _vm.$t("Valor"),
                            type: "text-number",
                            currency: "R$",
                            precision: 2,
                            validation: "required|min:0.01",
                            disabled: !_vm.canEditFieldsToUpdateInstallments,
                            title: _vm.$t(_vm.getTitleWhenCantUpdateValues),
                          },
                          model: {
                            value: _vm.payableInstallmentForm.value,
                            callback: function ($$v) {
                              _vm.$set(_vm.payableInstallmentForm, "value", $$v)
                            },
                            expression: "payableInstallmentForm.value",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "4" } },
                      [
                        _c("FormulateInput", {
                          staticClass: "required",
                          attrs: {
                            id: "installment_sidebar-due_date",
                            label: _vm.$t("Data de Vencimento"),
                            type: "datepicker",
                            placeholder: _vm.$t("dd/MM/aaaa"),
                            validation: "required",
                          },
                          model: {
                            value: _vm.payableInstallmentForm.dueDate,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.payableInstallmentForm,
                                "dueDate",
                                $$v
                              )
                            },
                            expression: "payableInstallmentForm.dueDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "installment_sidebar-comment",
                            label: _vm.$t("Observação"),
                            type: "textarea",
                            rows: "5",
                            disabled: _vm.isEdit && _vm.isFromPurchasOrSale,
                          },
                          model: {
                            value: _vm.payableInstallmentForm.comments,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.payableInstallmentForm,
                                "comments",
                                $$v
                              )
                            },
                            expression: "payableInstallmentForm.comments",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }