var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      false
        ? _c(
            "b-col",
            {
              staticClass: "mb-1 d-flex justify-content-end",
              attrs: { md: "12" },
            },
            [
              _c("e-button", {
                attrs: {
                  text: _vm.$t("Adicionar parcela"),
                  variant: "primary",
                },
                on: { click: _vm.showInstallmentSidebar },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-col",
        { attrs: { md: "12" } },
        [
          _c(
            "FormulateForm",
            { ref: "formBillPayment", attrs: { name: "formBillPayment" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c("b-table", {
                        staticClass: "bordered",
                        attrs: {
                          "show-empty": "",
                          responsive: "",
                          fields: _vm.fields,
                          "empty-text": _vm.$t("Nenhuma parcela adicionada"),
                          items: _vm.installmentsList,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "cell(action)",
                            fn: function (row) {
                              return [
                                _c("e-grid-actions", {
                                  attrs: {
                                    "show-update": !row.item.paymentDate,
                                    "show-delete": false,
                                  },
                                  on: {
                                    update: function ($event) {
                                      return _vm.showInstallmentSidebar(
                                        row.item
                                      )
                                    },
                                  },
                                }),
                              ]
                            },
                          },
                          {
                            key: "custom-foot",
                            fn: function () {
                              return [
                                _c("tr", [
                                  _c(
                                    "th",
                                    {
                                      staticClass: "text-right",
                                      attrs: { colspan: "5" },
                                    },
                                    [_vm._v(" Total ")]
                                  ),
                                  _c("th", { staticClass: "text-right" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.getTotalInfos.totalValue
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("th", { staticClass: "text-right" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.getTotalInfos.totalPaidValue
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("th"),
                                  _c("th", { staticClass: "text-right" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.getTotalInfos.totalDiscountValue
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("th"),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("account-payable-installment-sidebar", {
        ref: "installmentSidebar",
        attrs: {
          "is-expense": "",
          "is-from-purchase": !!_vm.formData.purchaseOrderId,
          "is-from-sale": !!_vm.formData.saleId,
        },
        on: {
          "update-installment": _vm.updateInstallment,
          "add-installment": _vm.addInstallment,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }