<template>
  <e-sidebar
    :show="showSidebar"
    :fetching="loading"
    :title="
      isEdit ? `${$t('Alterar parcela')} ${payableInstallmentForm.installment}` : $t('Nova parcela')
    "
    width="600px"
    @save="saveInstallment"
    @hidden="hide"
  >
    <template #content>
      <FormulateForm
        ref="payableInstallmentForm"
        name="payableInstallmentForm"
      >
        <b-row>
          <b-col>
            <e-payment-method
              id="installment_sidebar-payment_method"
              v-model="payableInstallmentForm.paymentMethod"
              :placeholder="$t('Selecione')"
              :required="true"
              :is-read-only="isEdit && isFromPurchasOrSale"
              value-is-object
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="8">
            <FormulateInput
              id="installment_sidebar-value"
              v-model="payableInstallmentForm.value"
              :label="$t('Valor')"
              type="text-number"
              currency="R$"
              :precision="2"
              class="required"
              validation="required|min:0.01"
              :disabled="!canEditFieldsToUpdateInstallments"
              :title="$t(getTitleWhenCantUpdateValues)"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="installment_sidebar-due_date"
              v-model="payableInstallmentForm.dueDate"
              :label="$t('Data de Vencimento')"
              type="datepicker"
              :placeholder="$t('dd/MM/aaaa')"
              class="required"
              validation="required"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <FormulateInput
              id="installment_sidebar-comment"
              v-model="payableInstallmentForm.comments"
              :label="$t('Observação')"
              type="textarea"
              rows="5"
              :disabled="isEdit && isFromPurchasOrSale"
            />
          </b-col>
        </b-row>
      </FormulateForm>
    </template>
  </e-sidebar>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { accountTypes } from '@/mixins'
import ESidebar from '@/views/components/ESidebar.vue'
import { mapGetters } from 'vuex'
import { getInitialInstallmentExpense } from '@/store/pages/financial/accounts-payable/expense-maintain'
import EPaymentMethod from '@/views/components/inputs/EPaymentMethod.vue'

export default {
  components: { BRow, BCol, ESidebar, EPaymentMethod },

  mixins: [accountTypes],

  props: {
    isExpense: {
      type: Boolean,
      default: false,
    },
    isReceive: {
      type: Boolean,
      default: false,
    },

    isFromPurchase: {
      type: Boolean,
      default: false,
    },
    isFromSale: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      saving: false,
      showSidebar: false,
      payableInstallmentForm: getInitialInstallmentExpense(),
    }
  },

  computed: {
    ...mapGetters('app', ['paymentMethodsObjectOptions']),
    ...mapGetters('pages/financial/accountsPayable/expenseMaintain', [
      'canEditFieldsToUpdateInstallments',
      'getTitleWhenCantUpdateValues',
    ]),

    isEdit() {
      return !!this.payableInstallmentForm.id || !!this.payableInstallmentForm.localId
    },

    isFromPurchasOrSale() {
      return !!(this.isFromPurchase || this.isFromSale)
    },
  },

  methods: {
    async show(payload) {
      try {
        this.loading = true
        this.showSidebar = true
        if (payload) {
          await new Promise(resolve => setTimeout(() => resolve(), 200))
          this.payableInstallmentForm = { ...payload }
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.loading = false
      }
    },

    hide() {
      this.showSidebar = false
      this.payableInstallmentForm = getInitialInstallmentExpense()
    },

    saveInstallment() {
      this.$refs.payableInstallmentForm.showErrors()
      if (this.$refs.payableInstallmentForm.hasErrors) {
        this.showInvalidDataMessage()
        return
      }

      if (this.isEdit) {
        this.$emit('update-installment', this.payableInstallmentForm)
      } else {
        this.$emit('add-installment', this.payableInstallmentForm)
      }

      this.hide()
    },
  },
}
</script>

<style></style>
