var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormulateForm",
    { ref: "formBill", attrs: { name: "formBill" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "3" } },
            [
              _vm.isRecordTypeAuto ||
              _vm.hasInstallmentPaid ||
              _vm.hasPurchaseOrder
                ? _c("FormulateInput", {
                    attrs: {
                      type: "label",
                      filter: "storeName",
                      title: _vm.$t(
                        "ACCOUNTS_PAYABLE.VALIDATION_ERROR.STORE_ID_CAN_NOT_CHANGE"
                      ),
                      label: _vm.$t("Loja"),
                    },
                    model: {
                      value: _vm.formData.store,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "store", $$v)
                      },
                      expression: "formData.store",
                    },
                  })
                : _c("e-store-combo", {
                    attrs: {
                      required: !_vm.hasPurchaseOrder,
                      "only-active-options": "",
                    },
                    model: {
                      value: _vm.formData.storeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "storeId", $$v)
                      },
                      expression: "formData.storeId",
                    },
                  }),
            ],
            1
          ),
          _vm.isEdit
            ? _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _vm.formData.purchaseOrderId
                    ? _c("FormulateInput", {
                        attrs: {
                          id: "bill-purchase_order",
                          label: _vm.$t("Id do pedido"),
                          type: "label",
                        },
                        model: {
                          value: _vm.formData.purchaseOrderId,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "purchaseOrderId", $$v)
                          },
                          expression: "formData.purchaseOrderId",
                        },
                      })
                    : _vm._e(),
                  _vm.formData.saleId
                    ? _c("FormulateInput", {
                        attrs: {
                          id: "bill-sale_id",
                          label: _vm.$t("Id da venda"),
                          type: "label",
                        },
                        model: {
                          value: _vm.formData.saleId,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "saleId", $$v)
                          },
                          expression: "formData.saleId",
                        },
                      })
                    : _vm._e(),
                  _vm.formData.payBox
                    ? _c("FormulateInput", {
                        attrs: {
                          id: "bill-pay_box_id",
                          label: _vm.$t("Caixa"),
                          type: "label",
                        },
                        model: {
                          value: _vm.formData.payBox.number,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData.payBox, "number", $$v)
                          },
                          expression: "formData.payBox.number",
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-col",
            { attrs: { md: _vm.isEdit ? 3 : 5 } },
            [
              _c("FormulateInput", {
                staticClass: "required",
                attrs: {
                  id: "bill-description",
                  label: _vm.$t("Descrição"),
                  type: "text",
                  validation: "required",
                },
                model: {
                  value: _vm.formData.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "description", $$v)
                  },
                  expression: "formData.description",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "bill-document-type",
                  label: _vm.$t("Tipo do documento"),
                  type: "vue-select",
                  options: _vm.documentTypeOptions(),
                },
                model: {
                  value: _vm.formData.documentType,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "documentType", $$v)
                  },
                  expression: "formData.documentType",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _c("e-only-number-input", {
                attrs: {
                  id: "bill-document",
                  required: "",
                  label: _vm.$t("Nº do documento"),
                },
                model: {
                  value: _vm.formData.document,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "document", $$v)
                  },
                  expression: "formData.document",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _vm.canEditFieldsToUpdateInstallments && !_vm.hasPurchaseOrder
                ? _c("FormulateInput", {
                    staticClass: "required",
                    attrs: {
                      id: "bill-value",
                      name: "billValue",
                      label: _vm.$t("Valor"),
                      type: "text-number",
                      filter: "currency",
                      currency: "R$",
                      precision: 2,
                      validation: "^required|min:0.01",
                      instructions: [
                        {
                          text: _vm.$t(
                            "Caso altere uma parcela, este valor será alterado."
                          ),
                        },
                        {
                          text: _vm.$t(
                            "ACCOUNTS_PAYABLE.UI.INSTRUCTION.INSTALLMENT_GENERATION"
                          ),
                        },
                      ],
                    },
                    on: { blur: _vm.updateInstallments },
                    model: {
                      value: _vm.formData.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "value", $$v)
                      },
                      expression: "formData.value",
                    },
                  })
                : _c("FormulateInput", {
                    attrs: {
                      id: "bill-value",
                      value: _vm.formData.value,
                      label: _vm.$t("Valor"),
                      name: "billValue",
                      type: "label",
                      title: _vm.$t(_vm.getTitleWhenCantUpdateValues),
                      filter: "currency",
                      disabled: _vm.hasPurchaseOrder,
                      instruction: _vm.$t(
                        "Caso altere uma parcela, este valor será alterado."
                      ),
                    },
                  }),
            ],
            1
          ),
          _vm.isExpense
            ? _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _vm.canEditFieldsToUpdateInstallments
                    ? _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "bill-expense-installment-quantity",
                          name: "installmentQuantity",
                          type: "number",
                          label: _vm.$t("Qtd. Parcelas"),
                          validation: "^required|min:1",
                          instructions: [
                            {
                              text: _vm.$t(
                                "Quantidade de parcelas que a despesa possui."
                              ),
                            },
                            {
                              text: _vm.$t(
                                "ACCOUNTS_PAYABLE.UI.INSTRUCTION.INSTALLMENT_GENERATION"
                              ),
                            },
                          ],
                        },
                        on: { blur: _vm.updateInstallments },
                        model: {
                          value: _vm.formData.installmentQuantity,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "installmentQuantity",
                              _vm._n($$v)
                            )
                          },
                          expression: "formData.installmentQuantity",
                        },
                      })
                    : _c("FormulateInput", {
                        attrs: {
                          id: "bill-expense-installment-quantity",
                          name: "installmentQuantity",
                          label: _vm.$t("Qtd. Parcelas"),
                          title: _vm.$t(_vm.getTitleWhenCantUpdateValues),
                          type: "label",
                          instruction: _vm.$t(
                            "Quantidade de parcelas que a despesa possui."
                          ),
                        },
                        model: {
                          value: _vm.formData.installmentQuantity,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "installmentQuantity",
                              _vm._n($$v)
                            )
                          },
                          expression: "formData.installmentQuantity",
                        },
                      }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _vm.canEditFieldsToUpdateInstallments
                ? _c("FormulateInput", {
                    staticClass: "required",
                    attrs: {
                      id: "bill-due-date",
                      name: "firstDueDate",
                      label: _vm.$t("Primeiro Venc."),
                      type: "datepicker",
                      placeholder: _vm.$t("dd/mm/aaaa"),
                      dropup: "",
                      validation: "required",
                      instructions: [
                        {
                          text: _vm.$t(
                            "Data de vencimento da primeira parcela. Essa data é usada como base para gerar as outras parcelas. Ex: 03/05, 03/06, 03/07, etc."
                          ),
                        },
                        {
                          text: _vm.$t(
                            "ACCOUNTS_PAYABLE.UI.INSTRUCTION.INSTALLMENT_GENERATION"
                          ),
                        },
                      ],
                    },
                    on: { blur: _vm.updateInstallments },
                    model: {
                      value: _vm.formData.firstDueDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "firstDueDate", $$v)
                      },
                      expression: "formData.firstDueDate",
                    },
                  })
                : _c("FormulateInput", {
                    attrs: {
                      id: "bill-due-date",
                      name: "firstDueDate",
                      label: _vm.$t("Primeiro Venc."),
                      type: "label",
                      filter: "date",
                      title: _vm.$t(_vm.getTitleWhenCantUpdateValues),
                      instruction: _vm.$t(
                        "Data de vencimento da primeira parcela. Essa data é usada como base para gerar as outras parcelas. Ex: 03/05, 03/06, 03/07, etc."
                      ),
                    },
                    model: {
                      value: _vm.formData.firstDueDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "firstDueDate", $$v)
                      },
                      expression: "formData.firstDueDate",
                    },
                  }),
            ],
            1
          ),
          _vm.isExpense
            ? _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("e-payment-method", {
                    attrs: {
                      id: "bill-expense-payment_method",
                      name: "paymentMethodToInstallments",
                      placeholder: _vm.$t("Selecione"),
                      clearable: false,
                      required: _vm.canEditFieldsToUpdateInstallments,
                      "is-read-only": !_vm.canEditFieldsToUpdateInstallments,
                      "value-is-object": "",
                      title: _vm.$t(_vm.getTitleWhenCantUpdateValues),
                      instructions: [
                        {
                          text: _vm.$t(
                            "Forma de pagamento da primeira parcela. Ela é usado como base para gerar as outras parcelas."
                          ),
                        },
                        {
                          text: _vm.$t(
                            "ACCOUNTS_PAYABLE.UI.INSTRUCTION.INSTALLMENT_GENERATION"
                          ),
                          hide: !_vm.canEditFieldsToUpdateInstallments,
                        },
                      ],
                    },
                    on: { input: _vm.updateInstallments },
                    model: {
                      value: _vm.formData.paymentMethodToInstallments,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData,
                          "paymentMethodToInstallments",
                          $$v
                        )
                      },
                      expression: "formData.paymentMethodToInstallments",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.isExpense
            ? _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("e-management-account", {
                    attrs: {
                      id: "bill-expense-management_account",
                      required: "",
                      name: "billExpenseManagementAccount",
                    },
                    model: {
                      value: _vm.formData.managementAccountId,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "managementAccountId", $$v)
                      },
                      expression: "formData.managementAccountId",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.isReceive
            ? _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("FormulateInput", {
                    staticClass: "required",
                    attrs: {
                      id: "bill-income-type",
                      label: _vm.$t("Tipo de receita"),
                      type: "select-with-button",
                      placeholder: _vm.$t("Selecione"),
                      options: _vm.incomeTypes,
                      validation: "required",
                      button_permission: true,
                    },
                    model: {
                      value: _vm.formData.incomeType,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "incomeType", $$v)
                      },
                      expression: "formData.incomeType",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.isExpense
            ? [
                _c(
                  "b-col",
                  { attrs: { md: "2" } },
                  [
                    _c("FormulateInput", {
                      attrs: {
                        id: "bill-person_type",
                        name: "personCategory",
                        type: _vm.isRecordTypeAuto ? "label" : "radio",
                        options: _vm.isRecordTypeAuto
                          ? _vm.personCategoryOptions()
                          : _vm.personCategoryRadioOptions(),
                        label: _vm.$t("Categoria do credor"),
                        "element-class": ["d-flex", "mt-1", "flex-wrap"],
                      },
                      model: {
                        value: _vm.formData.personCategory,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "personCategory", $$v)
                        },
                        expression: "formData.personCategory",
                      },
                    }),
                  ],
                  1
                ),
                _c("b-col", { attrs: { md: "6" } }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isCustomerCategory,
                          expression: "isCustomerCategory",
                        },
                      ],
                    },
                    [
                      _c("e-person-search", {
                        attrs: {
                          id: "bill-customer",
                          name: "billPersonCustomer",
                          "is-customer": "",
                          label: _vm.$t("Cliente"),
                          "default-options": _vm.getDefaultCustomerOptions,
                          required:
                            _vm.isCustomerCategory && !_vm.hasPurchaseOrder,
                          "is-read-only": !!_vm.formData.saleId,
                        },
                        model: {
                          value: _vm.formData.customerId,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "customerId", $$v)
                          },
                          expression: "formData.customerId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isSupplierCategory,
                          expression: "isSupplierCategory",
                        },
                      ],
                    },
                    [
                      _c("e-person-search", {
                        attrs: {
                          id: "bill-supplier",
                          name: "billPersonSupplier",
                          "is-supplier": "",
                          label: _vm.$t("Fornecedor"),
                          "default-options": _vm.getDefaultSupplierOptions,
                          required:
                            _vm.isSupplierCategory && !_vm.hasPurchaseOrder,
                          "is-read-only": _vm.hasPurchaseOrder,
                        },
                        model: {
                          value: _vm.formData.supplierId,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "supplierId", $$v)
                          },
                          expression: "formData.supplierId",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            : _vm._e(),
          _vm.isReceive
            ? _c(
                "b-col",
                { attrs: { md: "6" } },
                [
                  _c("FormulateInput", {
                    staticClass: "required",
                    attrs: {
                      id: "bill-client",
                      label: _vm.$t("Cliente"),
                      type: "select-searchable",
                      placeholder: _vm.$t("Cliente"),
                      validation: "required",
                      options: _vm.clientsFound,
                    },
                    on: { fetchSearch: _vm.searchClient },
                    model: {
                      value: _vm.formData.client,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "client", $$v)
                      },
                      expression: "formData.client",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c("FormulateInput", {
                staticClass: "required",
                attrs: {
                  id: "bill-statement-date",
                  label: _vm.$t("Data da Emissão"),
                  type: "datepicker",
                  placeholder: _vm.$t("dd/mm/aaaa"),
                  dropup: "",
                  disabled: _vm.hasPurchaseOrder,
                  validation: "required",
                },
                model: {
                  value: _vm.formData.statementDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "statementDate", $$v)
                  },
                  expression: "formData.statementDate",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("FormulateInput", {
                attrs: {
                  id: "bill-comments",
                  label: _vm.$t("Observação"),
                  type: "textarea",
                  rows: "2",
                },
                model: {
                  value: _vm.formData.comments,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "comments", $$v)
                  },
                  expression: "formData.comments",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }